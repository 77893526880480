

/* Responsive styles */
@media screen and (max-width: 768px) {
  .side-panel {
    width: 100%;
    margin-top: 32px;
  }
  .bar-chart-container {
    /* width: 80vw; */
  }
  
}
