@font-face {
  font-family: "GenSekiGothic";
  src: url("../assets/fonts/GenSekiGothic-L.ttc") format("truetype");
  font-weight: 300; /* Light */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("../assets/fonts/GenSekiGothic-R.ttc") format("truetype");
  font-weight: 400; /* Regular */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("../assets/fonts/GenSekiGothic-M.ttc") format("truetype");
  font-weight: 500; /* Medium */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("../assets/fonts/GenSekiGothic-B.ttc") format("truetype");
  font-weight: 700; /* Bold */
}

@font-face {
  font-family: "GenSekiGothic";
  src: url("../assets/fonts/GenSekiGothic-H.ttc") format("truetype");
  font-weight: 900; /* Heavy */
}

:root {
  font-family: "GenSekiGothic";
}
